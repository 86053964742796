
import { defineComponent } from 'vue';
import NProgress from 'nprogress';

export default defineComponent({
  name: 'NotFound',
  mounted() {
    NProgress.done();
  },
});
